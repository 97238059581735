.ReactTable {
    border-radius: 10px 10px 0px 0px !important;
    border: none !important;
    text-align: center !important;
}

.ReactTable .rt-thead.-header {
    color: white;
    border-radius: 10px 10px 0px 0px !important;
    background-color: #0656a3;
}

.extension-number-table  .rt-thead .rt-th {
    overflow: inherit !important;
}

.extension-number-table .rt-thead,
.extension-number-table .rt-tbody,
.extension-number-table .pagination-bottom {
    width: 98.5%;
}

.ReactTable .rt-thead.-filters {
    background-color: white;
}

.ReactTable .rt-tr.-even {
    background-color: white;
}

.ReactTable .rt-tr.-odd {
    background-color: #eef3f9 !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background-color: #e0e7ef !important;
}

.ReactTable .control-button {
    background-color: #808080;
    color: white !important;
    margin: 0px 2px 0px 2px;
}

.ReactTable .control-button:hover, .ReactTable .control-button:focus {
    background-color: #444;
    color: white;
    outline: none;
}

.ReactTable .fa-icon {
    margin: 1px 4px 0px 7px;
    width: 17px;
    height: 17px;
}

.ReactTable .rt-table {
    z-index: 0 !important;
}

.ReactTable .rt-tbody {
    overflow : overlay !important;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.ReactTable .row {
    margin-right : auto !important;
    margin-left : auto !important;
}

/* .ReactTable .control-button:focus {
    outline: none !important;
} */