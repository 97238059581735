.signIn {
    height: 10%;
    max-width: 400px;
    margin: 0 auto;
}

.form-block {
    background-color: white;
    text-align: center;
    padding: 30px 20px;
}

@media screen {
    .signIn {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        width: 60%;
    }
}

@media screen and (max-width: 767px) {
    .signIn {
        margin-top: 30px;
    }
}

.signIn div, .signIn button {
    margin-top: 10px;
}

.signIn .logIn-header {
    font-size: 40px;
    font-weight: bold;
    color: rgba(26, 37, 53, 0.9);
}

.signIn .form-control {
    border-color: white;
    border-width: 2px;
    background-color: #eef3f9;
}

.signIn .signIn-button {
    background-color: #0656a3;
    color: white;
    opacity: 0.8;
    margin: 0 auto;
    
}

.signIn .signIn-link {
    list-style: none;
    text-decoration: none;
}

.signIn .signIn-box {
    display: flex;
    align-items: center;
}

.signIn .spinning.glyphicon {
    animation: spin 1s infinite linear;
    margin-left: 0%;
}