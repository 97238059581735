.sidebar-child {
    display: block;
    width: 100%;
    color: white;
    font-size: 1.0em;
    background-color: transparent;
    text-align: center;
    border-radius: 0px !important;
    border: none;
    padding-top: 1em;
    margin-top: 0px !important;
    box-shadow: none !important;
}

.sidebar-child .sidebar-icon {
    float: left;
    margin-left: 10px;
}

.sidebar-group {
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.navbar-default .navbar-nav>li>a{
    color:black !important;
    
}

.navbar-default .navbar-nav>li>a:hover {
    color:white !important;
}

.dltext{
    margin-top: 10%;
    margin-bottom: 5px;
    color: white;
}

.omniphonebtn, .cusconbtn{
    color: white;
    margin-bottom: 3%;
    max-width: 88% !important;
    margin-left: 8% !important;
}

.cusconbtn{
    margin-bottom: 10% !important;
}
.omniphonebtn>img, .cusconbtn>img{
    width: 11%;
    margin-right: 4%;
}