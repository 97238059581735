html, body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}

input[type=file] {
    width: 100%;
}

#root {
    height: 100%;    
}

@media screen and (min-width: 768px) {
    body {
        overflow: hidden;
    }
}

.ReactTable {
    border-radius: 10px 10px 0px 0px !important;
    border: none !important;
    text-align: center !important;
}

.ReactTable .rt-thead.-header {
    color: white;
    border-radius: 10px 10px 0px 0px !important;
    background-color: #0656a3;
}

.extension-number-table  .rt-thead .rt-th {
    overflow: inherit !important;
}

.extension-number-table .rt-thead,
.extension-number-table .rt-tbody,
.extension-number-table .pagination-bottom {
    width: 98.5%;
}

.ReactTable .rt-thead.-filters {
    background-color: white;
}

.ReactTable .rt-tr.-even {
    background-color: white;
}

.ReactTable .rt-tr.-odd {
    background-color: #eef3f9 !important;
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background-color: #e0e7ef !important;
}

.ReactTable .control-button {
    background-color: #808080;
    color: white !important;
    margin: 0px 2px 0px 2px;
}

.ReactTable .control-button:hover, .ReactTable .control-button:focus {
    background-color: #444;
    color: white;
    outline: none;
}

.ReactTable .fa-icon {
    margin: 1px 4px 0px 7px;
    width: 17px;
    height: 17px;
}

.ReactTable .rt-table {
    z-index: 0 !important;
}

.ReactTable .rt-tbody {
    overflow : overlay !important;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.ReactTable .row {
    margin-right : auto !important;
    margin-left : auto !important;
}

/* .ReactTable .control-button:focus {
    outline: none !important;
} */


.modal-backdrop {
    background-color: #053e73;
}

.modal-content {
    border-radius: 10px;
}

.modal-header {
    border-radius: 10px 10px 0px 0px;
    background-color: #0656a3;
}

.modal-header .modal-title {
    font-weight: bold;
    color: white;
    margin-left: 3%;
}

.modal-header .x-execute {
    background-color: #0656a3;
    color: white;
    border: none;
    margin-right: 10px;
}

.modal-header .x-execute:focus {
    background-color: #0656a3;
    color: #ffffff80; 
    outline:none;
    box-shadow: none;
}

.modal-body {
    padding-top: 10px;
    line-height: 1;
    max-height: 70vh;
    overflow-y: auto;
}

.modal-body .panel {
    background-color: #eef3f9;
    border: none;
    border-radius: 10px;
    margin-right: 15px;
    margin-left: 15px;
}

.modal-body .panel-heading {
    background-color: white;
    border: none;
    padding-left: 5px;
}

.modal-body .panel-heading .panel-title {
    font-weight: bold;
    color: black;
    opacity: 0.9;
}

.modal-body .panel-body {
    padding: 25px;
}

.modal-body .panel-body .control-label {
    text-align: left;
    font-size: 16px;
    padding-top: 0px;
    padding-bottom: 6px;
    margin-left: -2%;
}

.modal-body .panel-body .control-button {
    margin: 0px 2px 0px 2px;
    border: 1px solid #ccc;
    color: #333
}

.modal-body .panel-body .control-button:hover, .modal-body .panel-body .control-button:focus {
    background-color: #444;
    color: white !important;
    outline: none;
}

.control-button-modal {
    background-color: #808080;
    color: white;
    margin: 0px 2px 0px 2px;
}

.control-button-modal:hover, .control-button-modal:focus {
    background-color: #444;
    color: white;
    outline: none;
}

.modal-body .multiValueFormMinus:focus, .modal-body .multiValueFormPlus:focus{
    outline: none;
}

.modal-body .panel-body .has-error .control-label {
    color:#C7241C;
}

.modal-body .panel-body .has-error .form-control {
    border-color: #C7241C;
}

.modal-body .panel-body .has-error .css-bg1rzq-control {
    border-color: #C7241C;
}

@media screen and (min-width: 992px) {
    .modal-body .panel-body .control-label {
        text-indent: 10px;
    }
}

.modal-body .panel-body .checkbox {
    padding-top: 0px;
}

.modal-body .panel-body p.form-control-static {
    padding-top: 0px;
}

.modal-body .panel-body .fa-icon {
    width: 17px;
    height: 17px;
}

.modal-body .panel-body .fa-icon-static {
    margin-left: 10px;
}

.modal-footer .row .panel-button {
    margin-right: 5%;
}

.modal-footer .row .panel-button .cancel-execute {
    margin-right: 15px;
    padding: 4px 20px;
}

.modal-footer .row .panel-button .ok-execute {
    padding: 4px 40px;
    background-color: #0e7edb;
}

.modal-footer .row .panel-button .ok-execute:hover {
    padding: 4px 40px;
    background-color: #0656a3;
}

.modal-footer .row .panel-button .ok-execute:focus, .modal-footer .row .panel-button .cancel-execute:focus{
    outline: none;
}


/*** 画面固有設定 ***/
/** 着信グループ管理 **/
.modal-body .panel-body .groupType-icon {
    padding-top: 7px;
}

.modal-body .panel-body .groupType-text {
    padding-right: 0px;

}
/** **/
/** 内線番号管理 **/
.modal-body .panel-body .inboundType-icon {
    padding-top: 7px;
}

.modal-body .panel-body .inboundType-text {
    padding-right: 0px;
}
/** **/
/** 音声管理 **/
.fileRow{
    margin-top: 1%;
}

/** **/
/** 会社管理 **/
.company_checkbox{
    margin-left: 2%;
}


/** **/
/** ユーザー管理 **/
.modal-body .lastName-form {
    padding-left: 0px;
}

.modal-body .lastName-form .control-label {
    margin-left: -3%;
}

.modal-body .firstName-form {
    padding-left: 0px;
    margin-right: 2%;
}

.modal-body .firstName-form .control-label {
    margin-left: -3%;
}
/** **/
/** 顧客管理 **/
.modal-body .multiValueFormMinus{
    margin-bottom: 1.5% !important;
}
.modal-body .panel-body .form-group .multiValueFormValue{
    margin-bottom: 1.5% !important;
} 

/** **/
/* 営業時間管理 */
.modal-body .calendar-Btn {
    padding: 0px;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: #1e72df;
    font-size: 15px;
}
.modal-body #update_Button{
    border: 1px solid #ccc;
}
.modal-body #update_Button:hover{
    background-color: #bdbdbd;
}

.modal-body .calendar-Btn:hover {
    padding: 0px !important;
    border: none !important;
    background-color: transparent !important;
    text-decoration: underline !important;
    color:  #23527c !important;
}
.modal-body .calendar-DataSettingLabel {
    text-indent: 10px;
    margin-bottom: 7px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
    display: inline-block;
}
.modal-body .calendar-UpdateRow {
    margin-bottom: 10px;
}

.modal-body .calendar-IreBtnAndLabel {
    padding-top: 20px;
}
.modal-body .calendar-IreBtn,.calendar-UpdateBtn {
    display: block;
    margin: 0 0 3px auto;
    border: 1px solid transparent !important;
    border-color: #ccc !important;
    padding: 7px 14px 7px 14px;
}

.modal-body .irr-btn-group{
    display: block;
    right: 0;
    bottom: -25px;
    position:absolute;
}
.modal-body .calendar-IreBtn:hover,.calendar-UpdateBtn:hover {
    border-color: #e6e6e6 !important;
    background-color: #bdbdbd !important;
}

.modal-body .rt-td{
    padding-top: 3%;
}

.modal-body .rt-tbody{
    border-left: 1px solid #cccccc60;
    border-right: 1px solid #cccccc60;
    border-bottom: 1px solid #cccccc60;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.modal-body .calendar-SettingForm {
    margin: 0 0 0 auto;
}

.modal-body .calendar-Modal_TimeCss {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 10%;
}
.modal-body .calendar-Modal_CalendarDraw
{
    display: inline-block;
    width: 154px;
    height: 26px;
}
.set_audio_button {
    margin-top: 1.2em;
    font-size: 20px !important;
}
.set_audio_button_business_calendar {
    margin-top: 0.2em;
    font-size: 20px !important;
}

.preview_add{
    color: #C7241C;
}

.preview_message{
    font-weight: bold;
}

.preview_time{
    margin-top: 1%;
}

.spanData{
    margin-top: 0.5%;
    display: block;
}

.exist_irr{
    margin-left: 5%;
}

.user-belonged{
    color: #C7241C;
    font-weight: bold;
    margin-left: 27%;
}

.asc-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
    z-index: 1;
}

.asc-block-ui .asc-block-icon-box {
    position: absolute;
    top: 40%;
    bottom: 55%;
    right: 60%;
    left: 50%;
}

.asc-block-ui .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #FFFFFF;
}
.set_control_parameter {
    margin-top: 0.6em;
}

.over-width-modal {
    width: 60% !important;
    max-width: none !important;
}

.checkbox-disabled {
    display: block !important;
}

.omniphone_login_status_message {
    margin-left: 0.6em;
    margin-top: 0.5em;
    color: #c7241c;
}
/***************/
/*** ***/
.LoadingButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
.signIn {
    height: 10%;
    max-width: 400px;
    margin: 0 auto;
}

.form-block {
    background-color: white;
    text-align: center;
    padding: 30px 20px;
}

@media screen {
    .signIn {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 60%;
    }
}

@media screen and (max-width: 767px) {
    .signIn {
        margin-top: 30px;
    }
}

.signIn div, .signIn button {
    margin-top: 10px;
}

.signIn .logIn-header {
    font-size: 40px;
    font-weight: bold;
    color: rgba(26, 37, 53, 0.9);
}

.signIn .form-control {
    border-color: white;
    border-width: 2px;
    background-color: #eef3f9;
}

.signIn .signIn-button {
    background-color: #0656a3;
    color: white;
    opacity: 0.8;
    margin: 0 auto;
    
}

.signIn .signIn-link {
    list-style: none;
    text-decoration: none;
}

.signIn .signIn-box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.signIn .spinning.glyphicon {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    margin-left: 0%;
}
.asc-audio {
    width: 100%;
}
.spinning.glyphicon {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    margin-left: 2000%;
}
@-webkit-keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
    from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
    to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

@font-face {
  font-family: 'fontello';
  src: url(/static/media/fontello.7696c6eb.eot);
  src: url(/static/media/fontello.7696c6eb.eot#iefix) format('embedded-opentype'),
       url(/static/media/fontello.3eb1fbbb.woff2) format('woff2'),
       url(/static/media/fontello.2255149d.woff) format('woff'),
       url(/static/media/fontello.d5378b43.ttf) format('truetype'),
       url(/static/media/fontello.1a2c9f0d.svg#fontello) format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?63821210#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-omni-contact:before { content: '\E800'; } /* '' */
.multiSelectPanel {
    height: auto;
    max-height: 23em;
    overflow-y: auto;
    border-radius: 4px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    margin-right: -2.5% !important;
}

.multiSelectAllButton {
    background-color: transparent !important;
    margin-left: -3% !important;
}

.multiSelectAllButton .allSelect:focus, .allSelect.click {
    outline: none;
}

.multiSelectAllButton .allReset:focus, .allReset.click {
    outline: none;
}

@media screen and (min-width: 992px) {
    .multiSelectAllButton .btn {
        display: block;
    }

    .multiSelectAllButton .allSelect {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 991px) {
    .multiSelectSearchForm {
        margin-top: 10px
    }
    
    .multiSelectAllButton .allSelect {
        margin-right: 10px;
    }
}

/*.list-group-item:hover, .list-group-item:active, .list-group-item.active, .list-group-item.active:focus {*/
    /*background-color: #e6e6e6 !important;*/
    /*color: black !important;*/
    /*outline: 0;*/
/*}*/
/*
    ホバー状態のみ色を変化する場合、アクティブ状態の項目をホバーした際の色を追加で設定しないと違和感あるのと
    .list-group-item:hoverのみ分離させて色変えるとアクティブから通常状態に戻した項目がホバー効かなくなる
    ※最後にアクティブから通常に戻したものが該当。
*/
.list-group-item:hover, .list-group-item:active, .list-group-item.active, .list-group-item.active:focus {
    z-index: 2;
    color: #fff !important;
    background-color: #8f9ba6 !important;
    border-color: #8f9ba6 !important;
}

.list-group-item:focus {
    background-color: white !important;
    color: #555 !important;
    outline: none !important;
    border-color: white !important;
}
.breadcrumb{
    background-color: #F2F2F2;
    font-weight: bold;
    margin-bottom: 5px;
    padding: 0px;
}

.Forbidden {
    padding-top: 100px;
    text-align: center;
}

.Header {
    margin: 0px;
    border: none !important;
    border-radius: 0px !important;
    background-color: white;
    text-align: center;
}

.Header .navbar-brand {
    color: white;
    font-weight: bold;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.Header-User-Icon button {
    border-radius: 50%;
    background-color: #cecece;
    color: white;
}

.Header-User-Icon button:hover, .Header-User-Icon button:focus{
    background-color: #808080 !important;
    color: white !important;
    outline: none !important
}

.Header-User-Icon button span {
    margin-top: 0.12em;
    font-size: 1em;
}

.disabled {
    display: none;
}

.Header-User-Div {
    position: absolute;
}
.sidebar-child {
    display: block;
    width: 100%;
    color: white;
    font-size: 1.0em;
    background-color: transparent;
    text-align: center;
    border-radius: 0px !important;
    border: none;
    padding-top: 1em;
    margin-top: 0px !important;
    box-shadow: none !important;
}

.sidebar-child .sidebar-icon {
    float: left;
    margin-left: 10px;
}

.sidebar-group {
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.navbar-default .navbar-nav>li>a{
    color:black !important;
    
}

.navbar-default .navbar-nav>li>a:hover {
    color:white !important;
}

.dltext{
    margin-top: 10%;
    margin-bottom: 5px;
    color: white;
}

.omniphonebtn, .cusconbtn{
    color: white;
    margin-bottom: 3%;
    max-width: 88% !important;
    margin-left: 8% !important;
}

.cusconbtn{
    margin-bottom: 10% !important;
}
.omniphonebtn>img, .cusconbtn>img{
    width: 11%;
    margin-right: 4%;
}
.App {
    font-family: Meiryo, Meiryo-Italic, MeiryoUI, MeiryoUI-Italic;
    height: 100%;
}

.App .container {
    width: auto;
    height: 100%;
    padding: 0px;
}

.asc-display-none {
    display: none;
}

.asc-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
}

.asc-block-ui .asc-block-icon-box {
    position: absolute;
    top: 40%;
    bottom: 55%;
    right: 60%;
    left: 50%;
}

.asc-block-ui .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #FFFFFF;
}

/* .app-auth-check .spinning.glyphicon {
    animation: spin 1s infinite linear;
} */

.bgGray {
    background-color: #8c8c8c;
}

.bgRed {
    background-color: red;
}

.colorWhite {
    color: white;
}

.signIn-logo-overview {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: skyblue;
    /* BtoC適用
     background-position: 90% 90%; */ 
    /* BtoB適用 */
    background-position: 50% 30%;
}

.signIn-form-overview {
    height: 100%;
    position: relative;
    background-color: #F2F4F4;
}

.img-logo {
    width: 80%;
    margin: 0 auto;
    width: 16em;
    height: 10%;
    max-width: 400px;
}

.signIn-logo-string {
    /* font-family: Georgia; */
    position: absolute;
    top: 30%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    color: #FFFFFF;
    font-size: 7em;
    text-shadow: 6px 3px 3px black;
    /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
    opacity: 0.8;
    /* font-style: oblique; */
    font-weight: bolder;
}

.table-button {
    margin: 0px 10px 10px 0px;
    border-radius: 10px;
    padding: 5px 15px;
    font-size: 14px;
    color: white;
    background-color: #272d36;
}
.table-button:hover, .table-button:focus, .table-button:active, .table-button.click {
    background-color: black !important;
    color: #ffffff95 !important;
    outline: none !important;
}

#callhistory-download{
    margin-top: 1% !important;
}


@media screen and (max-width: 1024px) {
    .signIn-logo-string {
        font-size: 5em;
    }
}

.header-app {
    padding: 0px;
}

@media screen and (max-width: 767px) {
    .header-nav {
        background-color: #0656a3;
        padding: 0px;
    }
}

.sidebar-app {
    background-color: #0656a3;
    padding: 0px;
    overflow-y: auto;
}

.sidebar-app button {
    background-color: #0656a3;
}

.sidebar-app button:hover, .sidebar-app button:focus, .sidebar-app button:active, .sidebar-app button.cuscon-active,
.header-nav .sidebar-child:hover, .header-nav .sidebar-child:focus, .header-nav .sidebar-child:active, .header-nav .sidebar-child.cuscon-active {
    background-color: #053e73 !important;
    color: white !important;
    outline: none !important;
}

.routes-app {
    overflow-y: auto;
    /* padding: 3rem 4rem; */
    padding: 20px 30px;
    background-color: #F2F2F2;
}

.hover-black:hover {
    cursor: pointer;
    color: black !important;
}

.margin-right-05 {
    margin-right: 0.5em !important;
}

.margin-left-05 {
    margin-left: 0.5em !important;
}

.margin-top-03 {
    margin-top: 0.3em !important;
}

.margin-top-05 {
    margin-top: 0.5em !important;
}
.margin-top-07 {
    margin-top: 0.7em !important;
}
.z-index-0 {
    z-index: 0;
}

.z-index-1500 {
    z-index: 1500;
}

.icon-omni-contact {
    padding-right: 0.8em !important;
}

.header-nav-item {
    background-color: black;
}
.result-count{
    color: #0656a3;
    text-align: right;
}

.select-width {
    min-width: 200px;
}

.container-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
}

.modal-checked-message{
    margin-top: 10px;
    font-size: small;
}

.csvImportMsg{
    margin-top: 10px;
}

a.csv-format-download-link{
    display: block;
    margin: 25px 0px 10px;
}

.ok-button-color{
    color: green;
}

.remove-button-color{
    background-color: #eee;
}

.default-button {
    padding: 4px 40px;
}

.ok-execute-btn {
    padding: 4px 40px;
    background-color: #0e7edb;
}

.ok-execute-btn:hover {
    background-color: #0656a3;
}

.label-color-333333 {
    color: #333333;
}

.extension-date-picker{
    width: 100%;
}

.fa-16 {
    font-size: 1.6em;
}

::-webkit-input-placeholder {
    color: #999;
    opacity: 1;
}

::placeholder {
    color: #999;
    opacity: 1;
}
