.App {
    font-family: Meiryo, Meiryo-Italic, MeiryoUI, MeiryoUI-Italic;
    height: 100%;
}

.App .container {
    width: auto;
    height: 100%;
    padding: 0px;
}

.asc-display-none {
    display: none;
}

.asc-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
}

.asc-block-ui .asc-block-icon-box {
    position: absolute;
    top: 40%;
    bottom: 55%;
    right: 60%;
    left: 50%;
}

.asc-block-ui .asc-block-icon-box .asc-block-icon {
    margin-bottom: 1em;
}

.asc-block-ui .asc-block-icon-box .asc-block-label {
    position: fixed;
    margin-left: -2em;
    color: #FFFFFF;
}

/* .app-auth-check .spinning.glyphicon {
    animation: spin 1s infinite linear;
} */

.bgGray {
    background-color: #8c8c8c;
}

.bgRed {
    background-color: red;
}

.colorWhite {
    color: white;
}

.signIn-logo-overview {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-color: skyblue;
    /* BtoC適用
     background-position: 90% 90%; */ 
    /* BtoB適用 */
    background-position: 50% 30%;
}

.signIn-form-overview {
    height: 100%;
    position: relative;
    background-color: #F2F4F4;
}

.img-logo {
    width: 80%;
    margin: 0 auto;
    width: 16em;
    height: 10%;
    max-width: 400px;
}

.signIn-logo-string {
    /* font-family: Georgia; */
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: #FFFFFF;
    font-size: 7em;
    text-shadow: 6px 3px 3px black;
    /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
    opacity: 0.8;
    /* font-style: oblique; */
    font-weight: bolder;
}

.table-button {
    margin: 0px 10px 10px 0px;
    border-radius: 10px;
    padding: 5px 15px;
    font-size: 14px;
    color: white;
    background-color: #272d36;
}
.table-button:hover, .table-button:focus, .table-button:active, .table-button.click {
    background-color: black !important;
    color: #ffffff95 !important;
    outline: none !important;
}

#callhistory-download{
    margin-top: 1% !important;
}


@media screen and (max-width: 1024px) {
    .signIn-logo-string {
        font-size: 5em;
    }
}

.header-app {
    padding: 0px;
}

@media screen and (max-width: 767px) {
    .header-nav {
        background-color: #0656a3;
        padding: 0px;
    }
}

.sidebar-app {
    background-color: #0656a3;
    padding: 0px;
    overflow-y: auto;
}

.sidebar-app button {
    background-color: #0656a3;
}

.sidebar-app button:hover, .sidebar-app button:focus, .sidebar-app button:active, .sidebar-app button.cuscon-active,
.header-nav .sidebar-child:hover, .header-nav .sidebar-child:focus, .header-nav .sidebar-child:active, .header-nav .sidebar-child.cuscon-active {
    background-color: #053e73 !important;
    color: white !important;
    outline: none !important;
}

.routes-app {
    overflow-y: auto;
    /* padding: 3rem 4rem; */
    padding: 20px 30px;
    background-color: #F2F2F2;
}

.hover-black:hover {
    cursor: pointer;
    color: black !important;
}

.margin-right-05 {
    margin-right: 0.5em !important;
}

.margin-left-05 {
    margin-left: 0.5em !important;
}

.margin-top-03 {
    margin-top: 0.3em !important;
}

.margin-top-05 {
    margin-top: 0.5em !important;
}
.margin-top-07 {
    margin-top: 0.7em !important;
}
.z-index-0 {
    z-index: 0;
}

.z-index-1500 {
    z-index: 1500;
}

.icon-omni-contact {
    padding-right: 0.8em !important;
}

.header-nav-item {
    background-color: black;
}
.result-count{
    color: #0656a3;
    text-align: right;
}

.select-width {
    min-width: 200px;
}

.container-block-ui {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(55, 59, 65, 0.5);
}

.modal-checked-message{
    margin-top: 10px;
    font-size: small;
}

.csvImportMsg{
    margin-top: 10px;
}

a.csv-format-download-link{
    display: block;
    margin: 25px 0px 10px;
}

.ok-button-color{
    color: green;
}

.remove-button-color{
    background-color: #eee;
}

.default-button {
    padding: 4px 40px;
}

.ok-execute-btn {
    padding: 4px 40px;
    background-color: #0e7edb;
}

.ok-execute-btn:hover {
    background-color: #0656a3;
}

.label-color-333333 {
    color: #333333;
}

.extension-date-picker{
    width: 100%;
}

.fa-16 {
    font-size: 1.6em;
}

::placeholder {
    color: #999;
    opacity: 1;
}