.Header {
    margin: 0px;
    border: none !important;
    border-radius: 0px !important;
    background-color: white;
    text-align: center;
}

.Header .navbar-brand {
    color: white;
    font-weight: bold;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.Header-User-Icon button {
    border-radius: 50%;
    background-color: #cecece;
    color: white;
}

.Header-User-Icon button:hover, .Header-User-Icon button:focus{
    background-color: #808080 !important;
    color: white !important;
    outline: none !important
}

.Header-User-Icon button span {
    margin-top: 0.12em;
    font-size: 1em;
}

.disabled {
    display: none;
}

.Header-User-Div {
    position: absolute;
}